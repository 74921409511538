import { useTranslation } from '@/utils/hook'
import Link from 'next/link'
import Image from 'next/image'

export function Banner({ userCount, creators }): JSX.Element {
  const t = useTranslation()

  const usersText = t('home.bannerSocialProof', {
    count: new Intl.NumberFormat().format(userCount)
  })
  const [beforeCount, afterCount] = usersText.split(
    new Intl.NumberFormat().format(userCount)
  )

  return (
    <section className="py-8 md:py-24">
      <div className="max-w-7xl mx-auto text-center">
        <div className="flex flex-col md:flex-row justify-center items-center">
          {/* Left Section: Avatars */}
          <div className="relative overflow-x-hidden rounded-full">
            <div className="flex -space-x-3">
              {creators.slice(0, 5).map((creator, index) => (
                <Image
                  key={index}
                  className="inline-block h-12 w-12 rounded-full bg-slate-50 object-cover dark:bg-white/80"
                  src={creator.avatar}
                  width={100}
                  height={100}
                  quality={100}
                  alt={`Avatar for user ${creator.name}`}
                />
              ))}
            </div>
          </div>

          {/* Right Section: Rating and Text */}
          <div className="ml-0 md:ml-4 mt-4 md:mt-0 flex flex-col items-center md:items-start text-center md:text-left">
            {/* Star Rating */}
            <div className="flex items-center">
              <Image
                src="/static/stars-4.5.svg"
                alt="HeyForm Rating"
                quality={100}
                width={180}
                height={32}
                priority
              />
            </div>

            {/* Text */}
            <div className="flex items-center text-xs sm:text-base text-slate-800 mt-2">
              <span className="mr-1">{beforeCount}</span>
              <span className="font-bold whitespace-nowrap">
                {new Intl.NumberFormat().format(userCount)}
              </span>
              <span className="ml-1">{afterCount}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="relative text-slate-900 max-w-5xl mx-auto px-8 md:px-12 z-10">
        <div className="pt-8 md:pt-16 text-left sm:text-center">
          <h1 className="max-w-5xl mx-auto pageTitle">
            {t('home.bannerHeadline')}
          </h1>
          <p className="max-w-2xl mx-auto mt-8 pageDesc">
            {t('home.bannerDesc')}
          </p>

          <div className="mt-8 pb-8 text-left sm:text-center">
            <div className="flex justify-start sm:justify-center items-start sm:items-center flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5">
              <Link
                href="https://my.heyform.net/sign-up"
                className="w-full sm:w-auto px-4 py-3 md:px-8 md:py-4 bg-slate-950 rounded-xl text-slate-50 sm:text-xl font-medium text-center"
                title={t('common.tryFree')}
              >
                {t('common.tryFree')}
              </Link>
              <Link
                href="/pricing"
                className="w-full sm:w-auto px-4 py-3 md:px-8 md:py-4 rounded-xl hover:bg-slate-50 border border-slate-950 text-slate-950 sm:text-xl font-medium text-center"
              >
                {t('common.findPlan')}
              </Link>
            </div>
          </div>

          <p className="md:text-xl">
            <span className="font-bold">HeyForm is free to try</span> for as
            long as you like
          </p>

          <div className="max-w-5xl mx-auto mt-16">
            <Image
              src="/static/banner.png"
              alt="HeyForm banner"
              className="w-full shadow-sm rounded-lg"
              quality={100}
              width={3840} // 图片实际宽度
              height={2880} // 图片实际高度
              priority
              unoptimized
            />
          </div>
        </div>
      </div>
    </section>
  )
}
