import styled from 'styled-components'
import { Flex } from '@/components/ui'
import { useTranslation } from '@/utils/hook'
import Image from 'next/image'

const apps = [
  {
    icon: 'https://forms.b-cdn.net/website/integrations/slack.svg',
    title: 'product.integrations.0.title',
    link: 'https://docs.heyform.net/integrations/slack'
  },
  {
    icon: 'https://forms.b-cdn.net/website/integrations/airtable.svg',
    title: 'product.integrations.1.title',
    link: 'https://docs.heyform.net/integrations/airtable'
  },
  {
    icon: 'https://forms.b-cdn.net/website/integrations/zapier.svg',
    title: 'product.integrations.2.title',
    link: 'https://docs.heyform.net/integrations/zapier'
  },
  {
    icon: 'https://forms.b-cdn.net/website/integrations/wordpress.svg',
    title: 'product.integrations.3.title',
    link: '/integrations/wordpress'
  },
  {
    icon: 'https://forms.b-cdn.net/website/integrations/hubspot.svg',
    title: 'product.integrations.4.title',
    link: 'https://docs.heyform.net/integrations/hubspot'
  },
  {
    icon: 'https://forms.b-cdn.net/website/integrations/dropbox.svg',
    title: 'product.integrations.5.title',
    link: '/integrations/dropbox'
  },
  {
    icon: 'https://forms.b-cdn.net/website/integrations/mailchimp.svg',
    title: 'product.integrations.6.title',
    link: 'https://docs.heyform.net/integrations/mailchimp'
  },
  {
    icon: 'https://forms.b-cdn.net/website/integrations/monday.svg',
    title: 'product.integrations.7.title',
    link: 'https://docs.heyform.net/integrations/monday'
  },
  {
    icon: 'https://forms.b-cdn.net/website/integrations/telegram.svg',
    title: 'product.integrations.8.title',
    link: 'https://docs.heyform.net/integrations/telegram'
  },
  {
    icon: 'https://forms.b-cdn.net/website/integrations/google-drive.svg',
    title: 'product.integrations.9.title',
    link: 'https://docs.heyform.net/integrations/google-drive'
  },
  {
    icon: 'https://forms.b-cdn.net/website/integrations/google-analytics.svg',
    title: 'product.integrations.10.title',
    link: 'https://docs.heyform.net/integrations/google-analytics'
  },
  {
    icon: 'https://forms.b-cdn.net/website/integrations/lark.png',
    title: 'product.integrations.11.title',
    link: 'https://docs.heyform.net/integrations/lark-suite'
  }
]
export function Integrations(): JSX.Element {
  const t = useTranslation()

  return (
    <div className="productIntegration py-16 md:py-32">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="sectionTitle">{t('product.integrationsHeadline')}</h2>
        <p className="sectionDesc">{t('product.integrationSubheading')}</p>

        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4 mt-12 mb-24">
          {apps.map((row, index) => (
            <Item className="mt-12 flex justify-center" key={index}>
              <a
                className="text-slate-900 text-left sm:text-center"
                href={row.link}
              >
                <Image
                  className="w-12 h-12 sm:w-16 sm:h-16 mx-0 mx-auto"
                  src={row.icon}
                  alt={`Icon for integration - ${t(row.title)}`}
                  width={64}
                  height={64}
                  sizes="(min-width: 640px) 64px, 48px"
                />
                <h3 className="mt-4 font-medium text-sm sm:text-base">
                  {t(row.title)}
                </h3>
              </a>
            </Item>
          ))}
        </div>
        <div className="max-w-2xl mx-auto text-center">
          <a
            href="/integrations"
            className="text-slate-950 text-lg font-medium underline"
          >
            {t('product.integrationButtom')}
          </a>
        </div>
      </div>
    </div>
  )
}

const Item = styled(Flex)``
