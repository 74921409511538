import { useTranslation } from '@/utils/hook'

const usageItems = [
  {
    imgSrc: 'https://forms.b-cdn.net/website/template-examples/creators.svg',
    altText: 'HeyForm for creators',
    titleKey: 'home.usageCreators',
    links: [
      {
        href: 'https://heyform.net/templates/customer-contact-form-template',
        textKey: 'home.usageCreators1'
      },
      {
        href: 'https://heyform.net/templates/customer-feedback-survey-template',
        textKey: 'home.usageCreators2'
      },
      {
        href: 'https://heyform.net/templates/workshop-registration-form-template',
        textKey: 'home.usageCreators3'
      }
    ]
  },
  {
    imgSrc: 'https://forms.b-cdn.net/website/template-examples/product.svg',
    altText: 'HeyForm for Product Managers',
    titleKey: 'home.usageProduct',
    links: [
      {
        href: 'https://heyform.net/templates/product-feedback-survey-template',
        textKey: 'home.usageProduct1'
      },
      {
        href: 'https://heyform.net/templates/product-registration-form-template',
        textKey: 'home.usageProduct2'
      },
      {
        href: 'https://heyform.net/templates/product-order-form-template',
        textKey: 'home.usageProduct3'
      }
    ]
  },
  {
    imgSrc: 'https://forms.b-cdn.net/website/template-examples/marketing.svg',
    altText: 'HeyForm for marketing',
    titleKey: 'home.usageMarketing',
    links: [
      {
        href: 'https://heyform.net/templates/free-trial-form-template',
        textKey: 'home.usageMarketing1'
      },
      {
        href: 'https://heyform.net/templates/lead-capture-form-template',
        textKey: 'home.usageMarketing2'
      },
      {
        href: 'https://heyform.net/templates/referral-form-template',
        textKey: 'home.usageMarketing3'
      }
    ]
  },
  {
    imgSrc: 'https://forms.b-cdn.net/website/template-examples/hr.svg',
    altText: 'HeyForm for HR',
    titleKey: 'home.usageHR',
    links: [
      {
        href: 'https://heyform.net/templates/exit-interview-form-template',
        textKey: 'home.usageHR1'
      },
      {
        href: 'https://heyform.net/templates/job-application-form-template',
        textKey: 'home.usageHR2'
      },
      {
        href: 'https://heyform.net/templates/employee-satisfaction-survey-template',
        textKey: 'home.usageHR3'
      }
    ]
  },
  {
    imgSrc: 'https://forms.b-cdn.net/website/template-examples/support.svg',
    altText: 'HeyForm for support',
    titleKey: 'home.usageSupport',
    links: [
      {
        href: 'https://heyform.net/templates/client-satisfaction-survey-template',
        textKey: 'home.usageSupport1'
      },
      {
        href: 'https://heyform.net/templates/brand-survey-form-template',
        textKey: 'home.usageSupport2'
      },
      {
        href: 'https://heyform.net/templates/customer-testimonials-template',
        textKey: 'home.usageSupport3'
      }
    ]
  },
  {
    imgSrc: 'https://forms.b-cdn.net/website/template-examples/education.svg',
    altText: 'HeyForm for education',
    titleKey: 'home.usageEducation',
    links: [
      {
        href: 'https://heyform.net/templates/student-enrollment-form-template',
        textKey: 'home.usageEducation1'
      },
      {
        href: 'https://heyform.net/templates/teacher-feedback-form',
        textKey: 'home.usageEducation2'
      },
      {
        href: 'https://heyform.net/templates/student-survey-template',
        textKey: 'home.usageEducation3'
      }
    ]
  }
]

function UsageItem({ titleKey, links }) {
  const t = useTranslation()

  return (
    <div className="rounded-lg bg-slate-200/80 px-6 py-12 mb-4">
      <h3 className="text-xl font-bold text-slate-900 mb-2">{t(titleKey)}</h3>
      <ul className="mb-0">
        {links.map((link, index) => (
          <li key={index}>
            <a
              href={link.href}
              className="text-lg text-slate-900 hover:underline underline-offset-1"
            >
              {t(link.textKey)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export function Usage() {
  const t = useTranslation()

  return (
    <div className="bg-slate-100/70 py-16 md:py-32">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="sectionTitle">{t('home.usageTitle')}</h2>
        <p className="sectionDesc">{t('home.usageDesc')}</p>

        <div className="usageGrid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-8 md:mt-16">
          {usageItems.map((item, index) => (
            <UsageItem key={index} {...item} />
          ))}
        </div>

        <div className="max-w-2xl mx-auto text-center mt-8">
          <a
            href="/templates"
            className="text-slate-950 text-lg font-medium underline"
          >
            {t('home.usageCTA')}
          </a>
        </div>
      </div>
    </div>
  )
}
