import { RiGithubFill } from '@remixicon/react'
import Link from 'next/link'

export function OpenSourceCTA() {
  return (
    <section className="bg-slate-50 py-24 px-4 text-center sm:text-left">
      <div className="max-w-3xl mx-auto">
        <h2 className="sectionTitle">
          And, it is{' '}
          <span className="md:highlight md:highlight-slate-300 md:highlight-variant-3 md:highlight-spread-xl md:text-slate-950">
            open-source
          </span>
        </h2>
        <p className="sectionDesc">
          Host HeyForm on your server. Be the owner of your form data and never
          miss any regulatory compliance.
        </p>
        <div className="flex justify-start md:justify-center">
          <Link
            className="bg-gray-900 hover:bg-gray-800 text-white font-medium py-3 px-4 rounded-md transition duration-300 ease-in-out inline-block mt-8"
            href="https://github.com/heyform/heyform"
            target="_blank"
          >
            <RiGithubFill className="inline mr-2 h-5 w-5" />
            Check us on GitHub
          </Link>
        </div>
        <div className="mt-16 grid grid-cols-1 sm:grid-cols-3 gap-8 text-left md:text-center">
          {[
            { value: '# 1', label: 'Trending of the Day' },
            { value: '6k+', label: 'Stars' },
            { value: '100k+', label: 'Deployments' }
          ].map((stat, index) => (
            <div key={index}>
              <div className="text-5xl font-bold text-gray-800 mb-2">
                {stat.value}
              </div>
              <div className="text-xl text-gray-600">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
