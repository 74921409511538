import { useTranslation } from '@/utils/hook'

const features = [
  {
    name: 'Create stunning forms in minutes',
    description:
      'Quickly create attractive forms by picking from an array of templates and adaptable components.',
    image: '/static/features/feature-create.png'
  },
  {
    name: 'Customize to match your brand',
    description:
      'Personalize forms with your brand colors, logos, and fonts to ensure a cohesive and professional look.',
    image: '/static/features/feature-customize.png'
  },
  {
    name: 'Collaborate and receive feedback in real-time',
    description:
      'Work together with your team, sharing real-time feedback and updates to improve form quality and effectiveness.',
    image: '/static/features/feature-team.png'
  },
  {
    name: 'Effortlessly collect and analyze data',
    description:
      'Streamline data collection and gain valuable insights with powerful analytics tools built into every form.',
    image: '/static/features/feature-analytics.png'
  },
  {
    name: 'Brand your workspace with a custom domain',
    description:
      'Create a professional impression by using a custom domain for your forms, reinforcing your brand identity.',
    image: '/static/features/feature-domain.png'
  },
  {
    name: 'Make it smart with conditional logic',
    description:
      'Create dynamic forms that respond to user inputs, enhancing user experience and data accuracy.',
    image: '/static/features/feature-logic.png'
  },
  {
    name: 'Reach a global audience with multilingual support',
    description:
      'Expand your reach by offering forms in multiple languages, making it easy to connect with a diverse audience.',
    image: '/static/features/feature-multilingual.png'
  },
  {
    name: 'Streamline your workflow automatically',
    description:
      'Seamlessly connect HeyForm with your existing software to automate workflows and boost productivity.',
    image: '/static/features/feature-integration.png'
  }
]

export function Feature(): JSX.Element {
  const t = useTranslation()
  return (
    <div className="bg-white py-16 md:py-32">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-6xl lg:px-8">
        <h2 className="text-4xl md:text-5xl font-bold text-slate-950 text-left sm:text-center">
          {t('product.featureHeadline')}
        </h2>

        <div className="mt-16 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-24 lg:grid-cols-3 md:gap-x-8 md:gap-y-16">
          {features.map(feature => (
            <div key={feature.name}>
              <div
                className="w-full md:w-[340px] h-[320px] bg-slate-200 rounded-md border border-slate-200"
                style={{
                  backgroundImage: `url(${feature.image})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right bottom'
                }}
              ></div>
              <div className="mt-6">
                <h3 className="text-2xl md:text-3xl font-extrabold text-slate-900 mb-4 tracking-normal">
                  {feature.name}
                </h3>
                <p className="mt-1 text-base text-gray-700 leading-normal">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
