import { Container } from '@/components'
import {
  Feature,
  Usage,
  Banner,
  Brand,
  Integrations,
  OpenSourceCTA,
  Cta,
  Testimonials
} from '@/components/home'
import { HomePageService } from '@/service'

const Home = ({ userCount, creators, testimonials }): JSX.Element => (
  <Container>
    <Banner userCount={userCount} creators={creators} />
    <Brand />
    <Feature />
    <Usage />
    <Integrations />
    <OpenSourceCTA />
    <Testimonials testimonials={testimonials} />
    <Cta />
  </Container>
)

export async function getServerSideProps(): Promise<unknown> {
  const [userCount, creators, testimonials] = await Promise.all([
    HomePageService.userCount(),
    HomePageService.creators(),
    HomePageService.testimonials()
  ])

  return {
    props: {
      userCount,
      creators,
      testimonials
    }
  }
}

export const config = {
  runtime: 'experimental-edge'
}

export default Home
